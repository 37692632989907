import React, { useState } from "react";
import './LandingPage.css'
import Programmer from '../Assets/Landing/web.png'
import SEO from '../Assets/Landing/broSearch.png'
import App from '../Assets/Landing/app.png'
import Fitmi from '../Assets/Landing/Product/fitmi.png'
import LandingHeader from "./Header/Header";
import LandingFooter from "./Footer/Footer";

function LandingPage(){

    return(
        <div className="pageBg">
            <LandingHeader/>
            <div className="space">
                <div className="LDo">
                    <div className="card">
                        <h2>Web Development</h2>
                        <p>We create custom websites that are fast, user-friendly, and look great on any device. From business websites to e-commerce platforms, we build solutions that fit your needs.</p>
                    </div>
                    <div className="cardImg">
                        <img src={Programmer}  /> 
                    </div>
                </div>
                <div className="LDo">
                    <div className="cardImg">
                        <img src={SEO}  /> 
                    </div>
                    <div className="card">
                        <h2>SEO Services</h2>
                        <p>We help your website get found on search engines like Google. Our SEO services improve your site’s visibility, bringing more traffic and potential customers to your business.</p>
                    </div>
                </div>
                <div className="LDo">
                    <div className="card">
                        <h2>App Development</h2>
                        <p>We develop mobile apps that provide smooth and engaging experiences for your users. Whether for Android or iOS, our apps are built to meet your business goals and deliver value to your audience.</p>
                    </div>
                    <div className="cardImg">
                        <img src={App}  /> 
                    </div>
                </div>
            </div>
            <div className="LServices center">
                <h2>Services we offer</h2>
                <div className="LSCards">
                    <div className="LSCard sImg1">
                        <h5>Web Development</h5>
                    </div>
                    <div className="LSCard sImg2">
                        <h5>SEO Services</h5>
                    </div>
                    <div className="LSCard sImg3">
                        <h5>App Development</h5>
                    </div>
                </div>
            </div>
            <div className="LProduct center">
                <h2>Our Product</h2>
                <div className="LPCards">
                    <div onClick={()=>window.open('https://www.fitmistudios.com/',"_blank")} className="LPCard ptr">
                        <div>
                            <img src={Fitmi} height='50px' />
                            <h5>Fitmi Studios</h5>
                            <p>Fitmi Studios is a gym management system that helps gyms manage members, payments, and trainers easily. It keeps everything organized in one place for a smoother gym experience.</p>
                        </div>
                    </div>
                </div>
            </div>
            <LandingFooter />
        </div>
    )
}
export default LandingPage