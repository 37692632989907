import React, { useEffect, useState } from "react";
import AddPost from "./AddPost";
import { addDoc, collection, deleteDoc, doc, getDocs, updateDoc } from "firebase/firestore";
import { database } from "./Config";
import Delete from '../Assets/delete.jpg'
import burger from '../Assets/menu.png'
import { useNavigate } from "react-router-dom";

function Admin(){
    const [close,setClose] = useState(false)
    const [showAdmin,setShowAdmin] = useState(false)
    const [menu,setMenu] = useState(true)
    const [feed,setFeed] = useState([])
    const [active,setActive] = useState('post')
    const navigate = useNavigate();

    const handleAdmin =(e)=>{
        e.preventDefault()
        if(e.target.name.value === 'my' && e.target.password.value ==='world' ){
            localStorage.setItem('name',e.target.name.value)
            localStorage.setItem('password',e.target.password.value)
            setShowAdmin(!showAdmin)
        }else{
            alert("You don't have access...")
        }
    }
    const feedbackCollection = collection(database,"feedback")
    const getBlogPost = async()=>{
        const data = await getDocs(feedbackCollection)
        // setBlog(data.docs.map((doc)=>({...doc.data(),id:doc.id})))
        setFeed(data.docs.map((doc)=>({...doc.data(),id:doc.id})))
    }


    useEffect(()=>{
        getBlogPost()
        if(localStorage.getItem('name') === 'my' && localStorage.getItem('password') ==='world' ){
            setShowAdmin(true)
        }
    })
    
    const handleOut =()=>{
        localStorage.setItem('name','')
        localStorage.setItem('password','')
        setShowAdmin(false)
    }
    const categoryCollection = collection(database,"category")
    const handleCategory =async(e)=>{
        e.preventDefault()
        if(e.target.category.value){
            await addDoc(categoryCollection,{language:e.target.category.value.toLowerCase()})
            alert('Category added successfully...')
        }
        e.target.category.value = ''
    }

    const handleCheck =async(id,read)=>{
        const docRef =  doc(database, "feedback", id);
        await updateDoc(docRef, {
            isRead: !read,
          });
    }

    const handleDelete =async(id)=>{
        const blogCollection = doc(database, "feedback", id);
        await deleteDoc(blogCollection)
        alert("Successfully Deleted...")
    }

    return(
        <div>
            <div onClick={()=>setMenu(!menu)} className="sideMenuIcon">
                <img src={burger} height='42px' alt="hamburgerMenu" />
            </div>
            {
                showAdmin?
                <div className="">
                    <div className={!menu?"sideMenu":"hideAdminMenu"}>
                        <div onClick={()=>setMenu(!menu)}>
                            <div className="adminLogo">
                                <span>Coding <span className="second_logo">Comics</span></span>
                            </div>
                        </div> 
                        <div className="menuList">
                            <div className={active == 'post'?'activeBg':'menu'} onClick={()=>setActive('post')}>
                                <span>Post</span>
                            </div>
                            <div className={active == 'feed'?'activeBg':'menu'} onClick={()=>setActive('feed')}>
                                <span>FeedBack</span>
                            </div>
                            <div className={active == 'extra'?'activeBg':'menu'} onClick={()=>setActive('extra')}>
                                <span>Extra</span>
                            </div>
                        </div>
                        <div className='menu logoutMenu' onClick={handleOut}>
                            <span>LogOut</span>
                        </div>
                    </div>
                    {/* start */}
                    <div className="rightColumn">
                    <div onClick={()=>navigate('/')} className="closeIcon alignLeft">X</div>
                        {active === 'post' ?
                        <div className="addPost">                    
                            {/* add category los */}
                            <div className="category">
                                <h2 className="pColor">Add Language list</h2>
                                <form onSubmit={(e)=>handleCategory(e)}>
                                    <input className="commonInput addCategory" name="category" placeholder="Category type" /><br/>
                                    <br/>
                                    <button className="commonBtn">Add</button>
                                </form>
                            </div>
                            <br/>
                            <hr/>
                            <div>
                                {/* <div className="buttonContainer">
                                    <button className="commonBtn" onClick={()=>setClose(!close)}>Add Post</button>
                                </div> */}
                                <AddPost/>
                            </div>
                        </div>
                        :''}
                        {/* feedback lst */}
                        {
                            active === 'feed'?
                            <div className="feedback">
                                <h2><u>Feedback</u></h2>
                                <ol>
                                {
                                    feed.map((val)=><li onClick={()=>handleCheck(val.id,val.isRead)}>
                                        <p><span className="feedTitle">Email:</span> <span  className={val.isRead?'strickout':''}>{val.email}</span></p>
                                        <span><span className="feedTitle">Comment:</span> <span  className={val.isRead?'strickout':''}>{val.feedback}</span></span>
                                        <div className="feedRow">
                                            <div className="feedbtn" onClick={()=>handleDelete(val.id)}><img src={Delete} height='20px' width='20px' alt={val.feedback} /></div>
                                        </div>
                                    </li>)
                                }
                                </ol>
                            </div>:''
                        }
                    </div>
                    {/* end */}
                </div>
                :
                // loginform
                <div className="loginForm">
                    <div className="centerForm">
                    <div className="logo">
                        <span>Coding <span className="second_logo">Comics</span></span>
                        <br/>
                        <div className="adminSpace">
                            <span className="adminSpan">Admin</span>
                        </div>
                    </div>
                        <form onSubmit={(e)=>handleAdmin(e)} className="login">
                            <input placeholder="Name" name="name" /><br/>
                            <input type="password" placeholder="Password" name="password" /><br/>
                            <button className="go">Go</button>
                        </form>
                    </div>
                </div>
            }
        </div>
    )
}
export default Admin;