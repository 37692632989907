import React, { useEffect, useState } from "react";
import "./Home.css";
import { collection, deleteDoc, doc, getDocs } from "firebase/firestore";
import { database } from "../Admin/Config";
import noResult from '../Assets/noResult.png'
import { createSearchParams, useNavigate } from "react-router-dom";
import ReactGA from 'react-ga';
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { Helmet } from "react-helmet";
import LandingHeader from "../LandingPage/Header/Header";
import LandingFooter from "../LandingPage/Footer/Footer";

function HomeScreen(){
    const [state,setState] = useState([])
    const [search,setSearch] = useState([])
    const [open,setOpen] = useState(false)
    const [result,setResult] = useState(false)
    
    const navigate = useNavigate();
    const getUrlLanguage = window.location.href.split('/')
    
    const getBlogPost = async()=>{
        const postRef = doc(database, 'blogPost', 'languages');
        const blogCollection = collection(postRef,getUrlLanguage[4])
        const data = await getDocs(blogCollection)
        setState(data.docs.map((doc)=>({...doc.data(),id:doc.id})))
        setSearch(data.docs.map((doc)=>({...doc.data(),id:doc.id})))
    }
    useEffect(()=>{
        getBlogPost()
        ReactGA.pageview(window.location.pathname)
    },[])

    const handleChange=(e)=>{
        e.preventDefault();
        var upCaseData=e.target.value
        var searchedVal =   search.filter(name => name.title.includes(upCaseData.toLowerCase())) // real filter in local js code
            setState(searchedVal)

        if(e.target.value.length == 0){
            getBlogPost()
            setState(state)
        }
        if(searchedVal.length == 0){
            setResult(true)
        }else{
            setResult(false)
        }
    }
    const urlSplit = window.location.href.split('/')
    const handleDelete =async(id)=>{
        const blogCollection = doc(database, 'blogPost', 'languages', urlSplit[4], id)
        await deleteDoc(blogCollection)
        alert("Successfully Deleted...")
    }
    const handleOpen = (title) =>{
        setOpen(!open)
        navigate({
            pathname:"page",
            search:createSearchParams({
                id:title,
            }).toString()
        })
    }

    const handleEdit =(id,details)=>{
        navigate('/comics?type=edit',{state:{id:id,data:details}});
    }

    return(
        <div className="pageBg">
            <Helmet>
                <meta charSet="utf-8" />
                <title>{getUrlLanguage[4]}</title>
                <meta name="description" content={getUrlLanguage[4]} />
                <meta name="og:title" property="og:title" content={getUrlLanguage[4]}/>
                <meta name="og:description" property="og:title" content={getUrlLanguage[4]}/>
            </Helmet>
            <div style={{margin:'0',padding:'0'}}>
                <LandingHeader/>
                <div className="containerMain">
                    <div className="search_container">
                        <div>
                            <input name="search" onChange={(e)=>handleChange(e)} className="search" placeholder="Type to Search" autoComplete="off" />
                            <button className="search_button">Search</button>
                        </div>
                    </div>
                    <div className="main center_div">
                        {state.length>0?
                        state.map((val,i)=>
                        <div className="cards" key={i}>
                            { localStorage.getItem("email") === 'akajith919@gmail.com' && localStorage.getItem('solution') === 'codingAjithComics' ?
                                <div className="postDelete" onClick={()=>handleDelete(val.id)}>X</div>:''
                            }
                            { localStorage.getItem("email") === 'akajith919@gmail.com' && localStorage.getItem('solution') === 'codingAjithComics' ?
                                <div className="postEdit" onClick={()=>handleEdit(val.id,val)}>E</div>:''
                            }
                            <div onClick={()=>{handleOpen(val.title)}}>
                                <div className="first_text">
                                    <div className="text_center">
                                        {val.title[0]}
                                    </div>
                                </div>
                                <div className="cardTitleContainer">
                                    <h1 className="card_title" title={val.title}>{val.title}</h1>
                                </div>
                            </div>
                        </div>
                        ):
                        <div>
                            <img src={noResult} height="400px" width="400px" alt="no result" />
                            <h1 className="loading">{result ? "No Result" : "Loading..."}</h1>
                        </div>
                        }
                    </div>
                </div>
                <div>
                    <LandingFooter/>
                </div>
            </div>
        </div>
    );
}
export default HomeScreen;