// import { initializeApp } from "firebase/app";

// const firebaseConfig = {
//   // apiKey: "AIzaSyDwL82-06R3X5d-E-Hj8YqIcYBRfMQ_WQs",
//   // authDomain: "codingcomics-blog.firebaseapp.com",
//   // projectId: "codingcomics-blog",
//   // storageBucket: "codingcomics-blog.appspot.com",
//   // messagingSenderId: "819752612263",
//   // appId: "1:819752612263:web:f11a6847ddecc373c08682",
//   // measurementId: "G-LFDB7BKM7Z"
// };

// const app = initializeApp(firebaseConfig);

import { initializeApp } from "firebase/app";
import { getAuth,GoogleAuthProvider } from "firebase/auth";
import { getStorage } from 'firebase/storage';
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: "AIzaSyDwL82-06R3X5d-E-Hj8YqIcYBRfMQ_WQs",
  authDomain: "codingcomics-blog.firebaseapp.com",
  projectId: "codingcomics-blog",
  storageBucket: "codingcomics-blog.appspot.com",
  messagingSenderId: "819752612263",
  appId: "1:819752612263:web:49f9b53ed20f2131c08682",
  measurementId: "G-0N53G62B1V"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app)
const imageDb = getStorage(app)
const database = getFirestore(app)
const provider = new GoogleAuthProvider();
export { auth,provider,imageDb,database };