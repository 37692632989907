import { collection, getDocs } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { database } from "../Admin/Config";
import { useNavigate } from "react-router-dom";
import './DynamicRoute.css';
import noResult from '../Assets/noResult.png'
import LandingHeader from "../LandingPage/Header/Header";
import LandingFooter from "../LandingPage/Footer/Footer";

function DynamicRoute(){
    const [language,setLanguage] = useState([])
    const navigate = useNavigate();

    const getDocuments =async()=>{
        const getLanguage = collection(database,"category")
        const data = await getDocs(getLanguage)
         setLanguage(data.docs.map((doc)=>({...doc.data(),id:doc.id})))

     }

    useEffect(()=>{
        getDocuments()
    },[])

    const handleClick =(lan)=>{
        navigate(`/blog/${lan}`)
    }

    return(
        <div className="pageBg">
            <LandingHeader/>
            <div className="languageContainer">
            <div className='main'>
                {language.length>0?
                language.map((val,i)=><div className="cards cardBg" onClick={()=>handleClick(val.language)}>
                    <p className="language">{val.language}</p>
                </div>)
            :
            <div>
                <img src={noResult} height="400px" width="400px" alt="no result" />
                <h1 className="loading">Loading...</h1>
            </div>
            }
            </div>
            </div>
            <div>
                <LandingFooter/>
            </div>
            {/* <ScrollTop/> */}
        </div>
    )
}
export default DynamicRoute;