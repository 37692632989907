import React from 'react'
import insta from '../../Assets/instagram.png'
import linkedin from '../../Assets/linkedin.png'
import github from '../../Assets/github-sign.png'
import youtube from '../../Assets/youtube.png'

function SocialLinks() {
    const instaLink = "https://www.instagram.com/coding__comics/"
    const linkedLink = "https://www.linkedin.com/in/ajith-kumar-s-a084861b0/"
    const gitLink = "https://github.com/AkajithAk"
    const ytLink = "https://www.youtube.com/@codingcomics"
  return (
    <div className="socialRow">
        <div>
            <a href={instaLink} target="_blank"><img src={insta} atr='instagram' className="socialLogo" /> </a>
        </div>
        <div>
            <a href={linkedLink} target="_blank"><img src={linkedin} atr='instagram' className="socialLogo" /> </a>
        </div>
        <div>
            <a href={gitLink} target="_blank"><img src={github} atr='instagram' className="socialLogo" /> </a>
        </div>
        <div>
            <a href={ytLink} target="_blank"><img src={youtube} atr='instagram' className="socialLogoYt" /> </a>
        </div>
    </div>
  )
}

export default SocialLinks