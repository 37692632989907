import React, { useEffect, useState } from "react";
import {auth,provider} from "./Config";
import './Common.css'
import { signInWithPopup } from "firebase/auth";
import AddPost from "./AddPost";
import { useNavigate } from "react-router-dom";

function GSignUp(){
    const [value,setValue] = useState('')
    const [close,setClose] = useState(false)

    const navigate = useNavigate()

    const handleClick =()=>{
        signInWithPopup(auth,provider).then((data)=>{
            setValue(data.user.email)
            localStorage.setItem("email",data.user.email)
            localStorage.setItem("name",data.user.displayName)
            if(data.user.email === 'akajith919@gmail.com'){
                localStorage.setItem("solution","codingAjithComics")
                window.location.reload()
            }
        })
    }

    useEffect(()=>{
        setValue(localStorage.getItem('email'))
    })

    const handleAdmin =()=>{
        navigate('/comics')
    }
return (
    <div>
        {localStorage.getItem('email') === 'akajith919@gmail.com' && localStorage.getItem('solution') === 'codingAjithComics' && (value)?
        <button className="commonBtn" onClick={handleAdmin}>I'm Comics</button>:''}
        {
        // (localStorage.getItem('email') ? <span className="displayName">{localStorage.getItem('email') === 'akajith919@gmail.com'? "":localStorage.getItem('name')}</span> : <div className="googleDiv" onClick={handleClick}>SignUp</div>)
        (localStorage.getItem('email') ? <span className="displayName">{localStorage.getItem('email') === 'akajith919@gmail.com'? "":localStorage.getItem('name')}</span> : '')
        }
    </div>
);
}
export default GSignUp;