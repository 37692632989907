import React from "react";
import insta from '../Assets/instagram.png'
import linkedin from '../Assets/linkedin.png'
import github from '../Assets/github-sign.png'
import youtube from '../Assets/youtube.png'

function Footer(){

    // social media links
    const instaLink = "https://www.instagram.com/coding__comics/"
    const linkedLink = "https://www.linkedin.com/in/ajith-kumar-s-a084861b0/"
    const gitLink = "https://github.com/AkajithAk"
    const ytLink = "https://www.youtube.com/@codingcomics"

    const currentDate = new Date();
    const year = currentDate.getFullYear();

    return(
        <div className="footer">
            <div>
                <h1> Coding Comics</h1>
                <p>This is a coding comics blog site, where you can discover excellent<br/> content related to React.js, JavaScript, HTML, CSS, bug fixes, and other tech-related topics.</p><br/>
            </div>
            <div className="socialRow">
                <div>
                    <a href={instaLink} target="_blank"><img src={insta} atr='instagram' className="socialLogo" /> </a>
                </div>
                <div>
                    <a href={linkedLink} target="_blank"><img src={linkedin} atr='instagram' className="socialLogo" /> </a>
                </div>
                <div>
                    <a href={gitLink} target="_blank"><img src={github} atr='instagram' className="socialLogo" /> </a>
                </div>
                <div>
                    <a href={ytLink} target="_blank"><img src={youtube} atr='instagram' className="socialLogoYt" /> </a>
                </div>
            </div>
            <div className="copyRight">
                <hr width='70%' />
                <h3 className="copyRightText">CopyRight &copy; {year} Coding Comics</h3>
            </div>
        </div>
    )
}

export default Footer;