import React, { useEffect, useState } from "react";
import './Common.css';
import { imageDb,database } from "./Config";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { v4 } from "uuid";
import { addDoc, collection, doc, getDocs, updateDoc } from "firebase/firestore";
import { useLocation } from "react-router-dom";

function AddPost(){
    const location = useLocation();
    let valueData = location.state; 
    const [inVal,setInVal] = useState('')
    const [category,setCategory] = useState([])
    const [db,setDb] = useState(valueData?valueData.data.language:'');
    let list;
    if(valueData?.data.listData){
        list= JSON.parse(valueData.data.listData)
    }
    const [data,setData]=useState(valueData?list:[{pTitle:"",multiCode:"",multiImgs:""}]) 

    const [values, setValues] = useState({
        title: valueData?valueData.data.title: '',
        desc: valueData?valueData.data.desc: '',
        ifameCode: valueData?valueData.data.ifameCode: '',
        gLink: valueData?valueData.data.gLink: '',
        ytLink: valueData?valueData.data.ytLink: '',
        suggestion: valueData?valueData.data.suggestion: [],
      });

    const handleClick=()=>{

        setData([...data,{pTitle:"",multiCode:"",multiImgs:"",imgtype:""}])
    }
    const handleChange=(e,i)=>{
        const {name,value,files}=e.target;
        const onchangeVal = [...data]
        if(name === 'pTitle'){
            onchangeVal[i][name]=value;
            setInVal(i)
        }
        if(name === 'multiCode'){
            onchangeVal[i][name]=value;
            setInVal(i)
        }
        else if(name === 'multiImgs'){
            const imgRef =  ref(imageDb,`blog/${v4()}`)
            uploadBytes(imgRef,files[0]).then(value=>{
                getDownloadURL(value.ref).then(url=>{
                        onchangeVal[inVal][name]=url;
                        onchangeVal[inVal]['imgtype']=files[0].type;
                    })
                })
        }
        setData(onchangeVal)
    }
    const handleDelete=(i)=>{
        const deleteVal = [...data]
        deleteVal.splice(i,1)
        setData(deleteVal)
    }

    const handleSubmit =async (e) =>{
        e.preventDefault()
        if(db ==''){
            alert('please select category...')
            return;
        }
        const postRef = doc(database, 'blogPost', 'languages');
        const valueDb = collection(postRef,db)
        // const updateValueDb = collection(postRef,db)
        let updateVal;
        if(valueData){
            updateVal = doc(database, 'blogPost', 'languages', db, valueData.id);
            // updateVal = doc(valueDb,valueData.id)
        }
        const splitVal = e.target.suggestion.value.split(',')
        if(e.target.title.value && e.target.desc.value){
            try{
                await (valueData?updateDoc:addDoc)(valueData?updateVal:valueDb,{
                    language:db,
                    title:e.target.title.value.toLowerCase(),
                    desc:e.target.desc.value,
                    ifameCode:e.target.ifameCode.value,
                    gLink:e.target.gLink.value,
                    ytLink:e.target.ytLink.value,
                    listData:JSON.stringify(data),
                    suggestion:splitVal,
                    created: new Date()
                })   
            alert('uploaded successfully...')
            }catch(e){
                alert(`please choose this - ${valueData.data.language}`)
            }
        }
    }
    // const changePostFromOneTOAnother =async (e) =>{
    //     e.preventDefault()
    //     // if(db ==''){
    //     //     alert('please select category...')
    //     //     return;
    //     // }
    //     const postRef = doc(database, 'blogPost', 'languages');
    //     const valueDb = collection(postRef,db)
    //     // const updateValueDb = collection(postRef,db)
    //     let updateVal;
    //     // if(valueData){
    //     //     updateVal = doc(database, 'blogPost', 'languages', db, valueData.id);
    //     //     // updateVal = doc(valueDb,valueData.id)
    //     // }
    //     const splitVal = e.target.suggestion.value.split(',')
    //     if(e.target.title.value && e.target.desc.value){
    //         try{
    //             await addDoc(valueDb,{
    //                 language:db,
    //                 title:e.target.title.value.toLowerCase(),
    //                 desc:e.target.desc.value,
    //                 ifameCode:e.target.ifameCode.value,
    //                 gLink:e.target.gLink.value,
    //                 ytLink:e.target.ytLink.value,
    //                 listData:JSON.stringify(data),
    //                 suggestion:splitVal,
    //                 created: new Date()
    //             })   
    //         alert('uploaded successfully...')
    //         }catch(e){
    //             alert(`please choose this - ${valueData.data.language}`)
    //         }
    //     }
    // }

    const handleInput =(e)=>{
        const { name, value } = e.target;
        setValues({
          ...values,
          [name]: value,
        });
    }

    
    const getDocuments =async()=>{
        const getLanguage = collection(database,"category")
        const data = await getDocs(getLanguage)
        setCategory(data.docs.map((doc)=>({...doc.data(),id:doc.id})))

     }

    useEffect(()=>{
        getDocuments()
    },[])

    return (
        <div>
            <div>
                <div className="addPost">
                    <h2 className="pColor">{valueData?'Update':'Add'} Post</h2>
                    <select value={db} className="languageList" onChange={(e)=>setDb(e.target.value)}>
                        {
                            category.map((val,i)=><option key={i}>{val.language}</option>)
                        }
                    </select>
                    <br/>
                    <br/>
                    <div className="postRow">
                        <form onSubmit={(e)=>handleSubmit(e)}>
                        {/* <form onSubmit={(e)=>changePostFromOneTOAnother(e)}> */}
                        <div className="mobile">
                                <button id="submit" className="postBtn">Publish</button>
                            </div>
                            <div className="addPostRow">
                                <div className="addPostRowContent">
                                    <div>
                                        <input name="title" value={values.title}  onChange={handleInput} placeholder="Title" /><br/><br/>
                                        <textarea name="desc" value={values.desc}  onChange={handleInput} placeholder="Description" /> <br/><br/>
                                        <input name="ifameCode" value={values.ifameCode}  onChange={handleInput} placeholder="Iframe" /> <br/><br/>
                                        <input  name="gLink" value={values.gLink}  onChange={handleInput} placeholder="Git Link" /> <br/><br/>
                                        <input  name="ytLink" value={values.ytLink}  onChange={handleInput} placeholder="Yt Link" /> <br/><br/>
                                    </div>
                                </div>
                            </div>
                            <div className="addPostRowContent">
                                <h3>Language Suggestion</h3>
                                <input name="suggestion" value={values.suggestion}  onChange={handleInput} placeholder="Add language with comma" />
                                <br/>
                                <br/>
                            </div>
                            <div className="desktop">
                                <button id="submit" className="postBtn">Publish</button>
                            </div>
                        </form>
                        <div>
                            <div className="dynamicDiv">
                                <button  className="postBtn" onClick={handleClick}>Add More</button>
                                <br/>
                                <br/>
                                <div className="multiContent">
                                    {
                                        data.map((val,i)=>

                                        <div key={i} className="newContent">
                                            <textarea className="multiTxt" name="pTitle" value={val.pTitle} onChange={(e)=>handleChange(e,i)} placeholder="Code Description" />
                                            <textarea className="multiTxt" name="multiCode" value={val.multiCode} onChange={(e)=>handleChange(e,i)} placeholder="Code" />
                                            <div className="twoBtn">
                                                <div className="uploadImg">
                                                    <label htmlFor="imgId">Upload pic</label>
                                                    <input id='imgId' className="multiImg" name="multiImgs" type="file" onChange={(e)=>handleChange(e,i)} accept="image/png, image/jpeg, image/jpg, .pdf, .doc, .docx" /><br/>
                                                </div>
                                                <button  className="deleteBtn" onClick={()=>handleDelete(i)}>Delete</button>
                                            </div>
                                        </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default AddPost;