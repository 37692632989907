import React from 'react'
import './Contact.css'
import LandingHeader from '../Header/Header'
import LandingFooter from '../Footer/Footer'
import SocialLinks from '../../Footer/SocialLinks/SocialLinks'
import { addDoc, collection } from 'firebase/firestore'
import { database } from '../../Admin/Config'

function Contact() {
  const valueDb = collection(database,"clientDetails")

  const handleClientDetail =async(e)=>{
    e.preventDefault()
    const name = e.target.name.value
    const phone = e.target.phone.value
    const email = e.target.email.value
    const desc = e.target.desc.value
    if(name && email && phone && desc ){
        await addDoc(valueDb,{
            name:name,
            phone:phone,
            email:email,
            desc:desc,
            isRead:false,
            created: new Date()
        })
        alert('Sent Successfully...')
        e.target.name.value = ''
        e.target.phone.value = ''
        e.target.email.value = ''
        e.target.desc.value = ''
    }
}

  return (
    <div className='pageBg'>
      <LandingHeader/>
      <div className='LContact space'>
        <div className="LDo">
            <div className="card">
              <h2>We're here to help</h2>
              <p>We are active on almost every social media platform. Feel free to reach out to us with any questions or concerns, and we’ll be happy to assist you. We guarantee a response within 24 hours.</p>
              <SocialLinks/>
            </div>
            <div className="cardImg">
              <div className='LForm'>
                <h1>Fill this..</h1>
                <form onSubmit={handleClientDetail}>
                  <input name='name' required placeholder='Name' /> <br/><br/>
                  <input name='phone' required placeholder='Phone Number' /> <br/><br/>
                  <input name='email' required placeholder='Email' /><br/><br/>
                  <textarea name='desc' required placeholder='Requirment' /> <br/><br/>
                  <button>Send</button>
                </form>
              </div>
            </div>
        </div>
      </div>
      <LandingFooter/>
    </div>
  )
}

export default Contact