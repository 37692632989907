import React from "react";
import GSignUp from "../Admin/GsignIn";
import logo from '../Assets/codingcomics.png'
import './Header.css'
import { useNavigate } from "react-router-dom";
import burger from '../Assets/menu.png'

function Header(props){
    const navigate = useNavigate();
    const urlLength = window.location.href.split('/').length;

    return(
        <div className={urlLength > 4 && window.innerWidth < 826?"logoSignup":"logoSignupMobile"}>
            <div className="headerChild">
                <div onClick={()=>props.hamburger(!props.close)} className={urlLength > 4 && window.innerWidth < 826? "burgerIcon hideMenu":"hideIcon"}>
                    <img src={burger} height='42px' alt="hamburgerMenu" />
                </div>
            </div>            
            <div  className="headerChild">
                <div onClick={()=>navigate('/')} className={urlLength > 4 && window.innerWidth < 826?"logo":"logo logoPdingLeft"}>
                    {/* Coding <span className="second_logo">Comics</span> */}
                    <img className="companyLogo" src={logo} height='45px' />
                </div>
            </div>
            <div  className="headerChild">
                <div className="gSignup">
                    <GSignUp/>
                </div>
            </div>
        </div>
    )
}
export default Header;