import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from "react-router-dom";

function LandingHeader() {
    const [active,setActive] = useState('/home')
    const [menu,setMenu] = useState(false)
    const navigate = useNavigate()
    const location = useLocation();

    const handleNavigation =(path)=>{
        navigate(`${path}`)
    }

    useEffect(()=>{
        setActive(location.pathname)
    })

    return(
        <div className="pageBg">
            <div className="LHeader">
                <div className="LName">
                    <h3 onClick={()=>navigate('/')}>Coding Comics</h3>
                </div>
                <div className="LMenuList">
                    <span onClick={()=>handleNavigation('/')} className={active == '/'? 'active':''} >Home</span>
                    <span onClick={()=>handleNavigation('/blog')} className={active == '/blog'? 'active':''} >Blog</span>
                    <span onClick={()=>handleNavigation('/contact')} className={active == '/contact'? 'active':''} >Contact Us</span>
                </div>
                <div onClick={()=>setMenu(!menu)} className="Lmenu">
                    {
                        menu? 
                        <div className="LCross">
                            x
                        </div>:
                        <div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    }
                </div>
            </div>
            <div>
                {
                    menu?
                        <div className="LMobileMenuList">
                            <div onClick={()=>handleNavigation('/')} className={active == '/home'? 'active':''} >Home</div>
                            <div onClick={()=>handleNavigation('/blog')} className={active == '/blog'? 'active':''} >Blog</div>
                            <div onClick={()=>handleNavigation('/contact')} className={active == '/contact'? 'active':''} >Contact Us</div>
                        </div>
                        :''
                }
            </div>
        </div>
    )
}
export default LandingHeader