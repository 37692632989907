import React from 'react'
import Logo from '../../Assets/codingcomics.png'
import SocialLinks from '../../Footer/SocialLinks/SocialLinks'

function LandingFooter() {

    const currentDate = new Date();
    const year = currentDate.getFullYear();

  return (
    <div>
        <div className="LFooter">
            <div>
                <img src={Logo} height='40px' />
                {/* <h1> Coding Comics</h1> */}
                <p>We provide web development, SEO, and app development services to help businesses grow online. <br/>Our team builds custom websites, improves search engine rankings, and creates mobile apps to meet your unique needs.</p><br/>
            </div>
            <div>
                <SocialLinks/>
            </div>
            <div className="copyRight">
                <hr width='70%' />
                <h5 className="copyRightText">&copy; {year}, Coding Comics. All Rights Reserved</h5>
            </div>
        </div>
    </div>
  )
}

export default LandingFooter